.cursor-pointer {
  cursor: pointer;
}

.font-medium {
  font-weight: 500;
}

.divider {
  background-color: rgba(255, 255, 255, 0.1);
  height: 1px;
  width: 100%;
}

.mb-10 {
  margin-bottom: 0.5rem;
}

.mb-16 {
  margin-bottom: 1rem;
}

.mb-24 {
  margin-bottom: 1.5rem;
}

.tooltip-inner {
  font-family: var(--bs-body-font-family);
}

.flex-between {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.disable-scrollbar {
  scrollbar-width: none; /* Firefox */
  -ms-overflow-style: none; /* Internet Explorer and Edge */

  /* Optional: Custom scroll behavior */
  &::-webkit-scrollbar {
    width: 0; /* Adjust the width according to your preference */
  }

  &::-webkit-scrollbar-track {
    background-color: transparent; /* Set to your desired color or remove to use the default */
  }

  &::-webkit-scrollbar-thumb {
    background-color: transparent; /* Set to your desired color or remove to use the default */
  }
}
